import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import SectionHeading from "../components/Items/SectionHeading";
// import Portfolios2 from "../components/Sections/Portfolios2";

function Works() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <section className="shadow-blue white-bg padding mt-0">
        <SectionHeading title="Portfolio" />
        {/* <Portfolios2 /> */}
        
      
        <div class="">

  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" data-bs-toggle="tab" href="#home">Websites</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-bs-toggle="tab" href="#menu1">Web Applications</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-bs-toggle="tab" href="#menu2">Logo Designs</a>
    </li>
    {/* <li class="nav-item">
      <a class="nav-link" data-bs-toggle="tab" href="#menu3">Menu 2</a>
    </li> */}
  </ul>


  <div class="tab-content mt-3">
    <div id="home" class="container tab-pane active p-0">

    <section class="gallery_sec">
                       
                         
			<div class="row align-items-center ">
				<div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio1.pdf" target="_blank">
        
                   <img src="images/portfolio.jpg" alt="nikola" /> 
                  
                </a>
      </div>

      <div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio2.pdf" target="_blank">
 
                     <img src="images/portfolio2.jpg" alt="nikola" /> 
                    
                </a>
      </div>

      <div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio3.pdf" target="_blank">
           
                     <img src="images/portfolio4.jpg" alt="nikola" /> 
                   
                </a>
      </div>

      <div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio6.pdf" target="_blank">
                
                    <img src="images/portfolio6.jpg" alt="nikola" /> 
                  
                </a>
      </div>
      <div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio7.pdf" target="_blank">

                    <img src="images/portfolio7.jpg" alt="nikola" /> 
                  
                </a>
      </div>
      <div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio8.pdf" target="_blank">

                    <img src="images/portfolio8.jpg" alt="nikola" /> 
                  
                </a>
      </div>

      <div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio9.pdf" target="_blank">
                  
                    <img src="images/portfolio9.jpg" alt="nikola" /> 
                  
                </a>
      </div>
      <div class="col-lg-6 list-group gallery mb-4">
        <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio7.pdf" target="_blank">

                    <img src="images/portfolio6.jpg" alt="nikola" /> 
                  
                </a>
      </div>
      </div>
      </section>
    </div>
    <div id="menu1" class="container tab-pane fade">
    <section class="gallery_sec">
                       
                         
                       <div class="row align-items-center ">
                         <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio5.pdf" target="_blank">
                           
                                     <img src="images/portfolio5.jpg" alt="nikola" /> 
                                   
                                 </a>
                       </div>
                 
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio3.pdf" target="_blank">
                           
                                     <img src="images/portfolio3.jpg" alt="nikola" /> 
                                     
                                 </a>
                       </div>
                 
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio10.pdf" target="_blank">
                          
                                     <img src="images/portfolio10.jpg" alt="nikola" /> 
                                    
                                 </a>
                       </div>
                 
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/portfolio11.pdf" target="_blank">
                             
                                     <img src="images/portfolio11.jpg" alt="nikola" /> 
                                   
                                 </a>
                       </div>
           
                       </div>
                       </section>
     </div>
    <div id="menu2" class="container tab-pane fade">
    <section class="gallery_sec">
                       
                         
                       <div class="row align-items-center ">
                         <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo1.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo1.jpg" />
                                     {/* <img src="images/portfolio5.jpg" alt="nikola" /> */}
                                   
                                 </a>
                       </div>
                 
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo2.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo2.jpg" />
                                     {/* <img src="images/portfolio3.jpg" alt="nikola" /> */}
                                     
                                 </a>
                       </div>
                 
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo3.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo3.jpg" />
                                     {/* <img src="images/portfolio10.jpg" alt="nikola" /> */}
                                    
                                 </a>
                       </div>
                 
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo4.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo4.jpg" />                                  
                                 </a>
                       </div>
                       
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo5.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo5.jpg" />                                  
                                 </a>
                       </div>
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo6.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo6.jpg" />                                  
                                 </a>
                       </div>

                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo7.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo7.jpg" />                                  
                                 </a>
                       </div>
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo8.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo8.jpg" />                                  
                                 </a>
                       </div>
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo9.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo9.jpg" />                                  
                                 </a>
                       </div>
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo10.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo10.jpg" />                                  
                                 </a>
                       </div>
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo11.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo11.jpg" />                                  
                                 </a>
                       </div>
                       <div class="col-lg-6 list-group gallery mb-4">
                         <a class="thumbnail fancybox" rel="ligthbox" href="images/logo12.jpg" target="_blank">
                                     <img class="img-responsive" alt="" src="images/logo12.jpg" />                                  
                                 </a>
                       </div>
                       </div>
                       </section>
                       </div>
    {/* <div id="menu3" class="container tab-pane fade">
      <h3>Menu 2</h3>
      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
    </div> */}
  </div>
</div>

      </section>
    </Layout>
  );
}

export default Works;
