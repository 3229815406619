import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
    year: "Jun 2019  - Present",
    degree: "Misha InfoTech Pvt Ltd",
    content:
      "Web & Graphics Designer",
  },
  {
    id: 1,
    year: "Jan 2018  - Jun 2019",
    degree: "Eglogics Softech Pvt Ltd",
    content:
      "Web & Graphics Designer",
  },
  {
    id: 2,
    year: "Dec 2016  -  Dec 2017",
    degree: "MS Soft Tech Pvt Ltd ",
    content:
      "Web Designer",
  },
  {
    id: 3,
    year: "Oct 2015  - Dec 2016",
    degree: "Soft Pro Inc Pvt Ltd ",
    content:
      "Web Designer",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
