import React from "react";
import { Link as ScrollLink } from "react-scroll";

const aboutData = {
  cvpath: "/images/Amit-WebDeveloper-Resume.pdf",
  image: "images/logo.png",
  name: "Amit Kumar",
  location: "Pilkhuwa, Ghaziabad",
  birthday: "7 July, 1989",
  email: "amittomer8786@gmail.com",
  phone: "7983667145, 8791895486",
  aboutMe:
    "I am Amit Kumar, a Front-end Designer from Pilkhuwa, Ghaziabad. I have 7 Years of experience in website design building and customization, also I am also good at Graphic design.",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={aboutData.image} alt={aboutData.name} className="logo-1" />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">Hello,</h2>
        <p className="mb-0">{aboutData.aboutMe}</p>
        <div className="row my-4">
          <div className="col-md-6">
            <p className="mb-2">
              Name: <span className="text-dark">{aboutData.name}</span>
            </p>
            <p className="mb-2">
              Birthday: <span className="text-dark">{aboutData.birthday}</span>
            </p>
            <p className="mb-0">
              Phone: <span className="text-dark">{aboutData.phone}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
            <p className="mb-2">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
          </div>
        </div>
        <a href={aboutData.cvpath} className="btn btn-default mr-3" target="_blank">
          <i className="icon-cloud-download"></i>Download CV
        </a>

        <a href="mailto:amittomer8786@gmail.com" className="btn btn-alt mt-2 mt-md-0 mt-xs-2"> <i className="icon-envelope"></i> Hire me</a>
       
      </div>
    </div>
  );
}

export default About;
